.weather-container {
    font-family: 'Poppins-Bold';
    background-repeat: no-repeat;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    width: 1080px;
    height: 1920px;
    padding: 85px;
    padding-top: 270px;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  
  .weather-container._2160x2880 {
    transform: scale(2);
    background-size: 2160px 2880px;
    transform-origin: top left;
  }
  
  .weather-container._1080x1440, .weather-container._2160x2880, .weather-container._1080x1620 {
    padding-top: 160px;
  }
  
  .weather-container._1920x960, .weather-container._1920x1080 {
    height: 960px;
    width: 1920px;
    padding: 75px;
    padding-top: 160px;
  }
  
  .weather-container._1920x1080 {
    padding-top: 220px;  
  }
  
  .weather-container._1080x1920 .content, 
  .weather-container._2160x2880 .content, 
  .weather-container._1080x1440 .content, 
  .weather-container._1080x1620 .content {
    flex-direction: column;
  }
  
  .current {
    height: 720px;
    width: 900px;
  }
  
  .weather-container._1080x1440 .current, 
  .weather-container._2160x2880 .current,
  .weather-container._1080x1620 .current {
    height: 700px;
  }
  
  .current .temp {
    position: relative;
    top: -40px;
    font-size: 350px;
    letter-spacing: 2.2px;
    padding-left: 100px;
  }
  
  .current .temp img {
    position: relative;
    top: 20px;
    vertical-align: text-top;
  }
  
  .updated {
    font-family: "Poppins-SemiBold";
    font-size: 25px;
    letter-spacing: -1.11px;
    position: absolute;
    top: 59px;
    right: 76px;
    display: inline-block;
  }
  
  .content {
    display: flex;
    align-items: stretch;
    text-align: center;
  }
  
  .weather-container._1080x1920 .forecast, 
  .weather-container._1080x1440 .forecast, 
  .weather-container._2160x2880 .forecast,
  .weather-container._1080x1620 .forecast {
    flex-direction: column;
  }
  
  .forecast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Poppins-Bold";
    width: 660px;
    padding-left: 30px;
  }
  
  
  .weather-container._1080x1920 .forecast .time, 
  .weather-container._1080x1440 .forecast .time, 
  .weather-container._2160x2880 .forecast .time,
  .weather-container._1080x1620 .forecast .time {
    max-width: 100px;
    font-size: 75px;
  }
  
  .weather-container._1080x1920 .forecast > div {
    height: 230px;
  }
  
   .weather-container._1080x1440 .forecast > div, 
   .weather-container._2160x2880 .forecast > div,
   .weather-container._1080x1620 .forecast > div {
    height: 185px;
   }
  
  .weather-container._1080x1920 .forecast, 
  .weather-container._1080x1440 .forecast, 
  .weather-container._2160x2880 .forecast,
  .weather-container._1080x1620 .forecast {
    width: 850px;
  }
  
  .weather-container._1080x1920 .forecast .temp, 
  .weather-container._1080x1440 .forecast .temp, 
  .weather-container._2160x2880 .forecast .temp,
  .weather-container._1080x1620 .forecast .temp {
    font-size: 105px;
  }
  
  .weather-container._1080x1920 .forecast > div > img, 
  .weather-container._1080x1440 .forecast > div > img, 
  .weather-container._2160x2880 .forecast > div > img,
  .weather-container._1080x1620 .forecast > div > img {
    max-width: 140px;
    max-height: 140px;
  }
  
  .weather-container._1080x1920 .forecast .temp img, 
  .weather-container._1080x1440 .forecast .temp img, 
  .weather-container._2160x2880 .forecast .temp img,
  .weather-container._1080x1620 .forecast .temp img {
    top: 35px;
    max-width: 33px;
    max-height: 33px; 
  }
  
  .weather-container._1080x1920 .forecast > div > img, 
  .weather-container._1080x1440 .forecast > div > img, 
  .weather-container._2160x2880 .forecast > div > img,
  .weather-container._1080x1620 .forecast > div > img {
    order: 3;
  }
  
  .weather-container._1080x1920 .forecast > div > div:last-child, 
  .weather-container._1080x1440 .forecast > div > div:last-child, 
  .weather-container._2160x2880 .forecast > div > div:last-child,
  .weather-container._1080x1620 .forecast > div > div:last-child {  
    order: 2;
  }
  
  .weather-container._1080x1920 .forecast > div:last-child,
  .weather-container._1080x1620 .forecast > div:last-child {
    display: flex;
  }
  
  .forecast > div > div:first-child {
    order: 1;
  }
  
  .forecast > div > img {
    order: 2;
  }
  
  .forecast > div > div:last-child {
    order: 3;
  }
  
  .forecast > div:last-child {
    display: none;
  }
  
  .weather-container._1080x1920 .forecast > div, 
  .weather-container._1080x1440 .forecast > div, 
  .weather-container._2160x2880 .forecast > div,
  .weather-container._1080x1620 .forecast > div {
    flex-direction: row;
  }
  
  .forecast .time {
    font-size:90px;
    letter-spacing: -3px;
  }
  
  .forecast > div > img {
    max-width: 167px;
    max-height: 167px;
  }
  
  .forecast .temp {
    font-size: 140px;
    letter-spacing: -4px;
    padding-left: 45px;
  }
  
  .forecast .temp img {
    max-width: 42px;
    max-height: 42px; 
    position: relative;
    vertical-align: top;
    top: 45px;
    left: 8px;
    display: none;
  }
  
  .forecast > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 643px;
  }

  .weather-container._1080x1920._300x250 {
    padding-top: 100px;
  }

  .weather-container._1080x1920._300x250 .current {
    height: 552px;
  }

  .weather-container._1080x1920._300x250 .current .temp {
    top: -110px;
  }

  .weather-container._1080x1920._300x250 .forecast > div {
    height: 143px;
  }

  .weather-container._1080x1920._160x600 {
    width: 540px;
    padding-left: 45px;
  }

  .weather-container._1080x1920._160x600 .current {
    width: 540px;
  }

  .weather-container._1080x1920._160x600 .current .temp {
    width: 540px;
    font-size: 194px;
    padding-left: 30px;
  }

  .weather-container._1080x1920._160x600 .current .temp img {
    width: 70px;
  }

  .weather-container._1080x1920._160x600 .forecast {
    width: 755px;
  }

  .weather-container._1080x1920._160x600 .forecast .temp img {
    display: none;
  }